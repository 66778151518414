const events = {
	LOGIN_ATTEMPT: 'login-attempt',
	LOGIN_FAILED: 'login-failed',
	LOGIN_SUCCESS: 'login-success',
	LOGOUT: 'logout',
	LOGGED_OUT_DUE_TO_INACTIVITY: 'logged-out-due-to-inactivity',
	FORCED_LOGOUT: 'forced-logout',
	CHANGE_PASSWORD_REQUIRED: 'change-password-required',
	CHANGE_PASSWORD_ATTEMPT: 'change-password-attempt',
	CHANGE_PASSWORD_FAILED: 'change-password-failed',
	CHANGE_PASSWORD_SUCCESS: 'change-password-success',
	PAGE_VIEW: 'page-view',
	SUBSCRIBED_TO_PUBNUB: 'subscribed-to-pubnub',
	UNSUBSCRIBED_FROM_PUBNUB: 'unsubscribed-from-pubnub',
	RECONNECTED_TO_PUBNUB: 'reconnected-to-pubnub',
	OT_SESSION_CREATED: 'ot-session-created',
	OT_SESSION_DESTROYED: 'ot-session-destroyed',
	OT_SESSION_DISCONNECTED: 'ot-session-disconnected',
	OT_SESSION_FAILED: 'ot-session-failed',
	OT_SESSION_RECONNECTED: 'ot-session-reconnected',
	OT_SESSION_RECONNECTING: 'ot-session-reconnecting',
	OT_CONNECTION_DESTROYED: 'ot-connection_destroyed',
	OT_CONNECTION_CREATED: 'ot-connection-created',
	OT_OTHER_CONNECTION_CREATED: 'ot-other-connection-created',
	OT_PUBLISHER_CREATED: 'ot-publisher-created',
	OT_PUBLISHER_DESTROYED: 'ot-publisher-destroyed',
	OT_PUBLISHER_FAILED: 'ot-publisher-failed',
	OT_ERROR: 'ot_error',
	NETWORK_STATUS_POOR: 'network_status_poor',
	NETWORK_STATUS_FAILED: 'network_status_failed',
	NETWORK_SPEED: 'network_speed',
	SHOWED_DISCONNECTION_TIMEOUT: 'showed_disconnection_timeout',
	CANCELED_DISCONNECTION_TIMEOUT: 'canceled_disconnection_timeout',
	TAB_VISIBLE: 'tab_is_visible',
	TAB_NOT_VISIBLE: 'tab_not_visible',
	DUPLICATE_SESSION: 'duplicate_session',
	INSTRUCTOR_CONNECTED_TO: 'instructor_connected_to',
	INSTRUCTOR_DISCONNECTED_FROM: 'instructor_disconnected_from',
	RECORDING_STARTED: 'recording_started',
	RECORDING_STOPPED: 'recording_stopped',
	SCREENSHARE_STARTED: 'screenshare_started',
	SCREENSHARE_STOPPED: 'screenshare_stopped',
	SENDING_DISCONNECT: 'sending_disconnect',
	SENDING_CONNECT: 'sending_connect',
	LEAVE_GROUP: 'leave_group',
	JOIN_GROUP: 'join_group',
	INVITED_TO_GROUP: 'invited_to_group',
};
const severity = {
	INFO: 'info',
	WARNING: 'warning',
	ERROR: 'error',
};
export { events, severity };
