import React, {
	createContext,
	useContext,
	useEffect,
	ReactNode,
	useState,
	useMemo,
	useCallback,
	memo
} from 'react';
import {
	LoginUserQuery,
	useGetAppSettingsQuery,
} from '@/apollo/gql/generated/graphql';
import { useRouter } from 'next/router';
import { useSetRecoilState } from 'recoil';
import {
	appSettingsAtom,
	classSlugAtom,
	isUserLoggingOutAtom,
	viewerIdAtom,
} from '@/recoil/atom/globalState';
import { customBugsnagLogger } from '@/utils/bugsnag';

type Props = {
	children: ReactNode;
};

type ContextType = {
	viewer?: LoginUserQuery['login'];
	getViewer: () => void;
	handleLogout: () => void;
	isStudent: boolean;
};

const Context = createContext<ContextType>(undefined as unknown as ContextType);

const ViewerProvider = ({ children }: Props) => {
	const router = useRouter();
	const { data } = useGetAppSettingsQuery();
	const [viewer, setViewer] = useState<LoginUserQuery['login']>(undefined);
	const [isStudent, setIsStudent] = useState<boolean>(false);
	const setViewerId = useSetRecoilState(viewerIdAtom);
	const setClassSlug = useSetRecoilState(classSlugAtom);
	const setIsLoggingOut = useSetRecoilState(isUserLoggingOutAtom);
	const setAppSettings = useSetRecoilState(appSettingsAtom);

	const getViewer = useCallback(async () => {
		const savedViewer = await localStorage.getItem('user');
		if (savedViewer) {
			const parsed = JSON.parse(savedViewer);
			setViewer(parsed);
			customBugsnagLogger.setUser(parsed);
			setViewerId(parsed.id);
			if (router?.query?.id) {
				setClassSlug(router?.query?.id as string);
			}
			if (parsed.role === 'student') {
				setIsStudent(true);
			}
		}
	}, [router?.query?.id, setClassSlug, setViewerId]);

	
	const handleLogout = useCallback(async () => {
		customBugsnagLogger.debug(`Logout ${viewer?.id}`);

		setIsLoggingOut(true);

		setTimeout(() => {
			const queryString = new URLSearchParams(router?.query as string).toString();
			window.location.href = `/dashboard?${queryString}`;
		}, 1000);
	}, [router?.query, setIsLoggingOut, viewer]);

	useEffect(() => {
		if (data?.getAppSettings) {
			const {
				defaultRemoteAccess,
				studentLogoutTimeout,
				instructorLogoutTimeout,
				defaultLogoutTimeout,
			} = data.getAppSettings;

			setAppSettings({
				defaultLogoutTimeout,
				studentLogoutTimeout,
				instructorLogoutTimeout,
				defaultRemoteAccess,
			});
		}
	}, [data, setAppSettings]);

	useEffect(() => {
		const savedViewer = localStorage.getItem('user');
		if (savedViewer && !viewer) {
			const parsed = JSON.parse(savedViewer);
			setViewer(parsed);
			setViewerId(parsed.id);
			
			customBugsnagLogger.setUser(parsed);
			if (router?.query?.id) {
				setClassSlug(router?.query?.id as string);
			}
			if (parsed.role === 'student') {
				setIsStudent(true);
			}
		}
	}, [router?.query?.id, setClassSlug, setViewerId, viewer]);


	const providerValues = useMemo(() => ({ getViewer, viewer, isStudent, handleLogout }), [getViewer, viewer, isStudent, handleLogout]);

	return (
		<Context.Provider value={providerValues}>
			{children}
		</Context.Provider>
	);
};

export const useViewerContext = (): ContextType => useContext(Context);

export default memo(ViewerProvider);
