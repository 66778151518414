import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { events, severity } from '@/config/log_events';
import { useViewerContext } from './useViewer';
	
type LoggingContextType = {
	containerIp?: string;
	loggingServerUrl?: string;
	setContainerIp: (containerIp: string) => void;
	setLoggingServerUrl: (loggingServerUrl: string) => void;
	handleLoggingToLambda: (message: any) => void;
	logToLambda: (eventType: string, options?: any) => void;
};

type Props = {
	children: React.ReactNode;
	containerIpAddress?: string;
	loggingServerUrlAddress?: string;
};

const Context = createContext<LoggingContextType>(
	undefined as unknown as LoggingContextType
);

const LoggingProvider: React.FC<Props> = ({
	children,
	containerIpAddress,
	loggingServerUrlAddress,
}) => {
	const [containerIp, setContainerIp] = useState(containerIpAddress);
	const router = useRouter();
	const {viewer} = useViewerContext();
	const slug = useMemo(() => router?.query?.id, [router]);
	const [loggingServerUrl, setLoggingServerUrl] = useState(
		loggingServerUrlAddress ?? ''
	);



	const logToLambda = useCallback(
		async (eventType, options = {}) => {
			const {
				severityLog,
				location,
				classSlug,
				user=viewer,
				centerCode,
				metadata = {},
			} = options;

			const logging = {
				eventType,
				severity: severityLog || severity.INFO,
				userId: parseInt(user.id, 10),
				userName: user.username,
				uuid: user.uuid,
				role: user.role,
				classSlug: classSlug || (slug as string),
				location: location || router.asPath,
				centerCode: centerCode || user.centerCode,
				metadata,
			};

			const message = `event: ${logging.eventType}, severity:${
				logging.severity
			} ,userId: ${logging.userId},userName: ${logging.userName},uuid: ${
				logging.uuid
			}, role:${logging.role}, centerCode: ${logging.centerCode}, classSlug:${
				logging.classSlug
			},location:${
				logging.location
			}, eventTime: ${new Date().toISOString()} , backendContainerIp: ${containerIp}, metadata: ${JSON.stringify(
				logging.metadata
			)}`;
			const serverUrl =
				loggingServerUrl === ''
					? process.env.LOGGING_SERVICE_URL
					: loggingServerUrl;

			console.log('%clogging','background: yellow' ,message);
			await fetch(serverUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					data: message,
				}),
			});
		},
		[containerIp, loggingServerUrl, router.asPath, slug, viewer]
	);

	useEffect(() => {
		if (viewer) {
			logToLambda(events.PAGE_VIEW, {
				location: router.asPath,
				user: viewer,
			});
		}
	}, [viewer]);
	
	const handleLoggingToLambda = useCallback(
		async (message) => {
			if (loggingServerUrl) {
				console.log('%clogging to lambda','color: hotpink', message);
				await fetch(loggingServerUrl, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						data: message,
					}),
				});
			}
		},
		[loggingServerUrl]
	);

	const containerIpValues = useMemo(
		() => ({
			containerIp,
			setContainerIp,
			loggingServerUrl,
			setLoggingServerUrl,
			logToLambda,
			handleLoggingToLambda,
		}),
		[containerIp, loggingServerUrl, logToLambda, handleLoggingToLambda]
	);
	return (
		<Context.Provider value={containerIpValues}>{children}</Context.Provider>
	);
};

export const useLoggingContext = (): LoggingContextType =>
	useContext(Context);

export default LoggingProvider;
